import React, { useEffect } from 'react';
import { MediaQueryProvider } from 'react-media-query-hoc';
import getConfig from 'next/config';
import { GetServerSideProps } from 'next';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';

import SkipLink from '@domain-group/fe-co-skip-link';
import { tokens } from '@domain-group/fe-brary';
import {
  AgencyHeroWithFragment,
  AgencyTopSummaryWithFragment,
  AgencyAboutSectionWithFragment,
  AgencyTeamWithFragment,
  AgencyRecommendationsWithFragment,
  AgencyVideoWithFragment,
  AgencyProfileMapWithFragment,
  AgencyCurrentListings,
  AgencyListingsStoryWithFragment,
  AgencySalesStatisticsStoryWithFragment,
  AgencySalesStatisticsWithFragment,
  AgencyEnquiryFormWithFragment,
  AgencyHeadWithQuery,
  ErrorBoundary,
  SectionWrapper,
  LoadingPage,
  AgencyProfileHeaderWithFragment,
  AgencyProfileFooterWithFragment,
  InView,
} from '../../components';
import { ShortlistProvider } from '../../contexts';

import { skipLinkName } from '../../helper/accessibility-utils';
import { addApolloState, initializeApollo } from '../../lib/apollo';
import { getDomainWinstonLogger } from '../../core/logger';
import { COMPONENT_IDS } from '../../constants';
import {
  trackAgencyAboutSection,
  trackAgencyHero,
  trackAgencyRecommendations,
  trackAgencyTeamSection,
  trackCurrentListings,
  trackSalesStats,
  trackProfilePage,
  trackAgencyEnquiryForm,
} from '../../tracking';
import { getUserDetails } from '../../utils';
import { version } from '../../../package.json';

import * as styles from '../../styles/trade-profile.style';
import { isAgencyWithoutAppraisalProcess } from '../../services/feature-flag-services';

const { publicRuntimeConfig } = getConfig();

const NOT_ENOUGH_DATA_ERROR =
  'Insufficient data to render Agency Profile page.';
const ARCHIVED_ERROR = 'Agency is archived.';

export const AGENCY_PROFILE_QUERY = gql`
  query getAgencyProfile($agencyId: Int!) {
    agency(agencyId: $agencyId) {
      id
      agencyId
      profileUrl
      isArchived
      address {
        suburb {
          slug
        }
      }

      ...${AgencyProfileHeaderWithFragment.fragmentName}
      ...${AgencyProfileFooterWithFragment.fragmentName}
      ...${AgencyHeroWithFragment.fragmentName}
      ...${AgencyTopSummaryWithFragment.fragmentName}
      ...${AgencyAboutSectionWithFragment.fragmentName}
      ...${AgencyTeamWithFragment.fragmentName}
      ...${AgencySalesStatisticsStoryWithFragment.fragmentName}
      ...${AgencySalesStatisticsWithFragment.fragmentName}
      ...${AgencyListingsStoryWithFragment.fragmentName}
      ...${AgencyRecommendationsWithFragment.fragmentName}
      ...${AgencyVideoWithFragment.fragmentName}
      ...${AgencyEnquiryFormWithFragment.fragmentName}
      ...${AgencyProfileMapWithFragment.fragmentName}
    }
  }

  ${AgencyProfileHeaderWithFragment.fragment}
  ${AgencyProfileFooterWithFragment.fragment}
  ${AgencyHeroWithFragment.fragment}
  ${AgencyTopSummaryWithFragment.fragment}
  ${AgencyAboutSectionWithFragment.fragment}
  ${AgencyTeamWithFragment.fragment}
  ${AgencySalesStatisticsStoryWithFragment.fragment}
  ${AgencySalesStatisticsWithFragment.fragment}
  ${AgencyListingsStoryWithFragment.fragment}
  ${AgencyRecommendationsWithFragment.fragment}
  ${AgencyVideoWithFragment.fragment}
  ${AgencyEnquiryFormWithFragment.fragment}
  ${AgencyProfileMapWithFragment.fragment}
`;

interface AgencyProfilePageProps {
  agencyId: number;
  isEmbeddedApp?: boolean;
  user?: {
    userId: string;
    sessionId: string;
    disableUserTracking: boolean;
    userName: string;
    givenName: string;
    familyName: string;
    postcode: string;
    userToken: string;
    emailHash: string;
    ipHash: string;
  };
  hideAppraisalCta?: boolean;
  mixpanelHasInitialized?: boolean;
}

const AgencyProfilePage = ({
  isEmbeddedApp = false,
  agencyId,
  user,
  hideAppraisalCta = false,
  mixpanelHasInitialized = false,
}: AgencyProfilePageProps): JSX.Element => {
  const { data, loading, error } = useQuery(AGENCY_PROFILE_QUERY, {
    variables: { agencyId },
    errorPolicy: 'all', // https://www.apollographql.com/docs/react/v2/data/error-handling/#error-policies
  });

  useEffect(() => {
    if (mixpanelHasInitialized && data?.agency) {
      trackProfilePage.trackPageViewAgencyProfile({
        locationId: data?.agency?.address?.suburb?.suburbSlug,
      });
    }
  }, [mixpanelHasInitialized, data?.agency, agencyId]);

  if (error) {
    throw new Error(`Something went wrong, ${error}`);
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (!data || !agencyId) {
    throw new Error(NOT_ENOUGH_DATA_ERROR);
  }

  // If agency is archived, throw error to be handled in getServerSideProps
  if (data.agency?.isArchived) {
    throw new Error(ARCHIVED_ERROR);
  }

  return (
    <ErrorBoundary>
      <>
        <AgencyHeadWithQuery agencyId={agencyId} user={user} />
        <SkipLink anchorName={skipLinkName} />
        <div
          css={styles.page}
          data-testid="trade-profile"
          data-version={version}
        >
          <MediaQueryProvider>
            <ShortlistProvider>
              <AgencyHeroWithFragment
                agencyId={agencyId}
                renderComponent={
                  isEmbeddedApp ? null : (
                    <AgencyProfileHeaderWithFragment agencyId={agencyId} />
                  )
                }
                emailFormComponentId={COMPONENT_IDS.enquiryForm}
                onAgencyAddressClick={trackAgencyHero.agencyAddressClick}
                onAgencyLogoClick={trackAgencyHero.agencyLogoClick}
                onCallButtonClick={trackAgencyHero.callButtonClick}
                onEmailButtonClick={trackAgencyHero.emailButtonClick}
                onSocialClick={trackAgencyHero.trackSocialLink}
                desktopWidth={1232}
              />
              <SectionWrapper isShallow componentId={COMPONENT_IDS.summary}>
                <AgencyTopSummaryWithFragment agencyId={agencyId} />
              </SectionWrapper>
              <SectionWrapper
                isShallow
                isNarrow
                componentId={COMPONENT_IDS.about}
              >
                <AgencyAboutSectionWithFragment
                  agencyId={agencyId}
                  onReadMore={trackAgencyAboutSection.trackReadMore}
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.team}>
                <AgencyTeamWithFragment
                  agencyId={agencyId}
                  hideAppraisalCta={hideAppraisalCta}
                  onViewMore={trackAgencyTeamSection.trackViewMore}
                  onAgentCall={trackAgencyTeamSection.trackCall}
                  onAgentCallReveal={trackAgencyTeamSection.trackCallReveal}
                  onAgentGetAppraisal={trackAgencyTeamSection.trackGetAppraisal}
                  onAgentOpenEnquiryForm={
                    trackAgencyTeamSection.trackOpenEnquiryForm
                  }
                  onAgentEnquirySubmit={
                    trackAgencyTeamSection.trackEnquirySubmit
                  }
                  onAgentEnquirySubmitSuccess={
                    trackAgencyTeamSection.trackEnquirySubmitSuccess
                  }
                  onAgentEnquirySubmitError={
                    trackAgencyTeamSection.trackEnquirySubmitError
                  }
                  mobilePadding={tokens.spacing.m}
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.statistics}>
                <AgencySalesStatisticsStoryWithFragment agencyId={agencyId} />
                <AgencySalesStatisticsWithFragment
                  agencyId={agencyId}
                  tracking={{
                    onSuburbChange: trackSalesStats.agency.onSelectSuburb,
                  }}
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.currentListings}>
                <AgencyListingsStoryWithFragment agencyId={agencyId} />
                <AgencyCurrentListings
                  agencyId={agencyId}
                  tracking={{
                    onSelectListingStatus:
                      trackCurrentListings.agency.onSelectListingStatus,
                    onSelectSelectSortBy: trackCurrentListings.agency.onSortBy,
                    onSelectPropertyType:
                      trackCurrentListings.agency.onPropertyTypeChange,
                    onSelectSuburb: trackCurrentListings.agency.onSuburbChange,
                    onSelectBedrooms:
                      trackCurrentListings.agency.onBedroomChange,
                    onPageChange: trackCurrentListings.agency.onViewMore,
                    onListingCardClick:
                      trackCurrentListings.agency.onListingCardClick,
                  }}
                  mobilePadding={tokens.spacing.m} // Removes the margin for the picker on mobile
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.recommendations}>
                <AgencyRecommendationsWithFragment
                  agencyId={agencyId}
                  onPageRendered={
                    trackAgencyRecommendations.trackAgencyPageRendered
                  }
                  onVendorRecoViewAll={
                    trackAgencyRecommendations.trackAgencyViewAll
                  }
                  onVendorRecoRendered={
                    trackAgencyRecommendations.trackAgencyRendered
                  }
                  onVendorRecoImpression={
                    trackAgencyRecommendations.trackAgencyImpression
                  }
                  onVendorRecoModalViewMore={
                    trackAgencyRecommendations.trackAgencyModalViewMore
                  }
                  onVendorRecoModalPrev={
                    trackAgencyRecommendations.trackAgencyModalPrevious
                  }
                  onVendorRecoModalNext={
                    trackAgencyRecommendations.trackAgencyModalNext
                  }
                />
              </SectionWrapper>
              <SectionWrapper componentId={COMPONENT_IDS.video}>
                <AgencyVideoWithFragment agencyId={agencyId} />
              </SectionWrapper>
              <SectionWrapper
                componentId={COMPONENT_IDS.enquiryForm}
                bottomBorder={false}
              >
                <InView
                  onActivate={trackAgencyEnquiryForm.trackEnquiryFormViewed}
                >
                  <AgencyEnquiryFormWithFragment
                    agencyId={agencyId}
                    onSubmit={() =>
                      trackAgencyEnquiryForm.trackSubmit({
                        locationId: data?.agency?.address?.suburb?.suburbSlug,
                      })
                    }
                    // TODO: Missing MixPanel events
                    // onSelectIntent={}
                    // onSubmitIntent={}
                    // onSubmitSuccess={}
                    // onSubmitError={}
                  />
                </InView>
              </SectionWrapper>
              <SectionWrapper noStyles componentId={COMPONENT_IDS.map}>
                <AgencyProfileMapWithFragment agencyId={agencyId} />
              </SectionWrapper>
            </ShortlistProvider>
          </MediaQueryProvider>
        </div>
        {isEmbeddedApp ? null : (
          <AgencyProfileFooterWithFragment agencyId={agencyId} />
        )}
      </>
    </ErrorBoundary>
  );
};

export default AgencyProfilePage;

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  query,
}) => {
  const logger = getDomainWinstonLogger(__filename);
  const user = getUserDetails(req.user);

  // For GraphQL Query Data Requirements
  const agencySlug = query.searchParam;

  if (typeof agencySlug !== 'string') {
    return {
      notFound: true,
    };
  }
  const slug = agencySlug.match(/\d+$/)?.pop();
  const agencyId = parseInt(slug ?? '0', 10);

  if (!agencyId) {
    return {
      notFound: true,
    };
  }

  const noAppraisalService = await isAgencyWithoutAppraisalProcess(agencyId);

  const props = {
    // _app.tsx props
    serverInfo: res.locals.serverInfo,
    hideAppraisalCta: noAppraisalService,
    mixpanelToken: publicRuntimeConfig.MIXPANEL_TOKEN,

    isEmbeddedApp: req.isEmbeddedApp,

    // User data
    user,

    // Page props
    agencyId,
  };

  const pageProps = JSON.parse(
    JSON.stringify({
      props,
    }),
  );

  const apolloClient = initializeApollo();

  try {
    await getDataFromTree(
      <ApolloProvider client={apolloClient}>
        <AgencyProfilePage {...pageProps.props} />
      </ApolloProvider>,
    );

    const { agency } = apolloClient.readQuery({
      query: AGENCY_PROFILE_QUERY,
      variables: {
        agencyId,
      },
    });

    if (!agency) {
      return {
        notFound: true,
      };
    }

    // redirect to correct SEO url if there is a mismatch
    if (agency.profileUrl !== agencySlug) {
      return {
        redirect: {
          destination: `/real-estate-agencies/${agency.profileUrl}`,
          permanent: true,
        },
      };
    }

    return addApolloState(apolloClient, pageProps);
  } catch (error: any) {
    logger.error('GraphQL SSR error\n', error);

    // Handle 404s
    if (error?.message?.includes?.(NOT_ENOUGH_DATA_ERROR)) {
      return {
        notFound: true,
      };
    }

    // Handle archived agencies
    if (error?.message?.includes?.(ARCHIVED_ERROR)) {
      const { agency } = apolloClient.readQuery({
        query: AGENCY_PROFILE_QUERY,
        variables: {
          agencyId,
        },
      });
      return {
        redirect: {
          destination: agency?.address?.suburb?.slug
            ? `/real-estate-agencies/${agency.address.suburb.slug}`
            : '/real-estate-agents',
          permanent: true,
        },
      };
    }

    throw new Error('Agency Profile SSR error', error);
  }
};
